/* App.css 
@import url('http://fonts.googleapis.com/css2?family=Permanent+Marker&display=swap');
body, html, #root {
  /* background-color: #f2f2f2; 
  margin: 0;
  padding: 0;
  min-height: 100vh;
  font-family: 'Permanent Marker', cursive;
}
*/


@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');

body, html, #root {
  /*  background-color: #f2f2f2;  */
  margin: 0;
  padding: 0;
  min-height: 100vh;
  font-family: 'Poppins', 'Helvetica', sans-serif;
}
/* 
body, html, #root {
   background-color: #f2f2f2; 
  margin: 0;
  padding: 0;
  min-height: 100vh;
  font-family: 'Verdana', 'Geneva', 'Tahoma', sans-serif;
}

 */

 body {
  font-family: 'Poppins', 'Helvetica', sans-serif;
}
