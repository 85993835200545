/* Overlay for loading spinner */
.loader-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(128, 128, 128, 0.7); /* Grayish background with opacity */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999; /* Ensures loader is on top of everything */
  }
  
  /* Form container */
  .form-container {
    position: relative;
    
  }
  