/* view plans component css  */


.view-plan-container{
  background: linear-gradient(135deg, #e8d67d, #c93);

  text-align: center;
  color: #334450;
  padding: 20px;
  overflow-y: hidden;
  overflow-x: hidden;
}

.section-names{
 border-radius: 45px;
 width: fit-content;
 display: flex;
 /* justify-content: space-between; */
 /* background:linear-gradient(to right, #3498DB, #333333); */
 background-color: #fff;
 padding: 2px;
 font-family: 'Poppins', 'Helvetica', sans-serif;

}
  
.section-name-1{
  font-size: large;
  padding: 10px 10px;
  font-family: 'Poppins', 'Helvetica', sans-serif;
  color:black;
  transition: background-color 0.5s ease, border-radius 0.3s ease, font-weight 0.3s ease, color 0.3s ease;
}
  
.section-name-1:hover{
  border-radius: 45px;
  font-weight: bold;
  cursor: pointer;
  font-family: 'Poppins', 'Helvetica', sans-serif;

}

.section-name-1.selected {
  background-color: #007bff; /* Darker blue background for selected section */
  font-weight: bold;
  color: white;
  border-radius: 20px;
  font-family: 'Poppins', 'Helvetica', sans-serif;

}
  
.section-plan {
  flex: 1 1 calc(25% - 20px);
  background: linear-gradient(135deg, hsl(50, 70%, 70%), hsl(40, 60%, 50%));
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  height: auto;
  transition: transform 0.8s ease, box-shadow 0.3s ease, border 0.3s ease;
  font-family: 'Poppins', 'Helvetica', sans-serif;

}

.section-plan:hover {
  transform: scale(1.01);
  box-shadow: 0 4px 20px rgba(255, 193, 7, 0.6); /* Warm golden glow */
  border: 1px solid hsl(45, 80%, 55%); /* Brighter gold border */
}



.heading{
    color: #334450;
    text-align: center;
    font-family: 'Poppins', 'Helvetica', sans-serif;
  
}


.feature-name{
    color:#334450;
    padding: -2%;
    font-family: 'Poppins', 'Helvetica', sans-serif;
  
}

.feature-name h7{
  margin-right: 4px;
  font-family: 'Poppins', 'Helvetica', sans-serif;

}

.plans-container {
  height: 400px; /* Fixed height */
  overflow-y: auto; /* Enable vertical scrolling */
  border: 1px solid #ccc; /* Optional: Add border for better visibility */
  padding: 16px; /* Optional: Add padding inside the container */
}


.benefits{
  border-top: #336383 solid 2px;
}



/* Responsive Design */
@media (max-width: 768px) {
  .section-plan  {
    flex: 1 1 calc(50% - 20px);/* Two cards per row on smaller screens */
  }
}

@media (max-width: 480px) {
  .section-plan {
    flex: 1 1 100%; /* One card per row on very small screens */
  }

   .toggle-details-button {
    width: 100%; 
   }
}