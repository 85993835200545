.card {
    position: relative;
    transition: transform 0.3s ease;
  }
  
  .card.hovered {
    transform: scale(1.05);
  }
  
  .hover-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 8px 16px;
    background-color: rgba(0, 0, 0, 0.8);
    color: #fff;
    font-weight: bold;
    border-radius: 4px;
    white-space: nowrap;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.3);
    pointer-events: none;
    opacity: 1;
  }