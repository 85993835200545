/* buy plans component css  */

.section-planId {
  background: linear-gradient(135deg, #e8d67d, #c93);
  height: auto;
  transition: transform 0.8s ease, box-shadow 0.3s ease, border 0.3s ease;
  font-family: 'Poppins', 'Helvetica', sans-serif;

}

.section-planId:hover {
  transform: scale(1.01); /* Slightly enlarge the card */
  box-shadow: 0 4px 20px rgba(0, 123, 255, 0.6); /* Blue glow effect */
  font-family: 'Poppins', 'Helvetica', sans-serif;

}

.heading {
  color: #334450;
    text-align: center;
    font-family: 'Poppins', 'Helvetica', sans-serif;
  
}

.planId-feature-name {
  color:#334450;
  font-family: 'Poppins', 'Helvetica', sans-serif;

}

/* Responsive Design */
@media (max-width: 768px) {
  .section-planId {
    flex: 1 1 calc(50% - 20px); /* Two cards per row on smaller screens */
  }
}

@media (max-width: 480px) {
  .section-planId {
    flex: 1 1 100%; /* One card per row on very small screens */
  }
}



/* styles.css */
.red-button {
  background-color: #00649F;
  color: white;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
}

.red-button:hover {
  background-color: #b71c1c;
}

.green-button {
  background-color: #00649F;
  color: white;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
}

.green-button:hover {
  background-color: #2e7d32;
}

