
.nextPage-grids{
    border:0.5ps solid lightgrey;
    border-radius: 5px;
    padding: 5px;
    
}


.nextPage-button{
    color: beige;
    background-color:  #00649F;
    margin-left: 10px;
    padding: 1%;
    border: 1px solid #00649F;
    border-radius: 5px;
}

.nextPage-button:hover{
    background-color: #004970;
}


.add-on-system-box{
    border-bottom: 1px solid lightslategrey;
    border-top: 1px solid lightslategrey;
    padding: 1%;
    margin-top:3%;
    margin-bottom: 3%;
}

.selected-section-plan-details{
    font-weight: bold;
}


/* marginTop: "20px", marginLeft: "10px", padding:'1%', backgroundColor:'#00649F', border:'1px solid #00649F' */