/* Navbar container */
.navbar {
  display: flex;
  align-items: center;
  padding: 20px;
  position: sticky;
  top: 0;
  z-index: 1000;
  transition: background-color 0.3s ease, color 0.3s ease;
}

/* Navbar logo styling */
.navbar-logo {
  height: 50px;
  width: 50px;
  margin-right: 20px;
  border-radius: 50%;
  object-fit: cover;
}

/* Navbar menu styling */
.navbar-menu {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
  justify-content: flex-end;
}

/* Navbar item styling */
.navbar-item {
  margin: 0 1px;
}

/* Navbar link (button) styling */
.navbar-link {
  background: none;
  border: none;
  text-decoration: none;
  text-transform: capitalize;
  font-size: 1rem;
  color: #f2f2f2;
  cursor: pointer;
  transition: color 0.3s ease, opacity 0.3s ease;
  padding: 10px 15px;
  border-radius: 5px;
}

.navbar-link:hover {
  opacity: 0.8; /* Reduce opacity on hover */
  background-color: rgba(255, 255, 255, 0.1); /* Light hover effect */
}

/* Content area styling */
.content {
  padding: 10px;
  flex: 1;
}

/* Responsive design for smaller screens */
@media (max-width: 768px) {
  .navbar {
    flex-direction: column;
    align-items: flex-start;
  }

  .navbar-menu {
    flex-direction: column;
    align-items: flex-start;
    width: auto;
  }

  .navbar-item {
    margin: 5px 0;
  }
}


.navbar-item.active .navbar-link {
  font-weight: bold;
  text-decoration: underline;
}
