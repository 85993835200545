.whatsnew {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: linear-gradient(180deg, #4facfe, #00f2fe); /* Bluish gradient from top to bottom */
    color: #fff; /* White text for contrast */
    padding: 20px; /* Add some padding for better spacing */
    border-radius: 15px; /* Rounded corners for a modern look */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Soft shadow for depth */
    font-family: 'Arial', sans-serif; /* Elegant font family */
    font-size: 1.1em; /* Slightly larger text size */
    text-align: center; /* Center text alignment */
}


.whatsnew h6 {
    margin-bottom: 10px; /* Space below the heading */
    background: #fff; /* White background for the speech bubble */
    color: #333; /* Dark text color for contrast */
    padding: 10px 20px; /* Padding for the speech bubble */
    border-radius: 25px; /* Rounded corners for the speech bubble */
    position: relative; /* Position relative for the pseudo-element */
    opacity: 0.9; /* Start hidden */
    animation: fadeIn 0.4s ease-in-out 0.4s forwards; /* Fade in animation with delay */
}

.whatsnew h6::after {
    content: '';
    position: absolute;
    top: 100%; /* Position the triangle below the speech bubble */
    left: 30%;
    transform: translateX(-50%);
    border-width: 10px;
    border-style: solid;
    border-color: #fff transparent transparent transparent; /* Triangle pointing down */
}

@keyframes fadeIn {
    from {
        opacity: 1; /* Start hidden */
    }
    to {
        opacity: 1; /* Fade to visible */
    }
}


/* .whatsnew-paper{
    background-color: #00649F;
} */

.whatsnew p {
    font-size: 1em; /* Standard font size for paragraphs */
    margin: 5px 0; /* Space above and below paragraphs */
}

.whatsnew a {
    color: #ff7e5f; /* Link color matching the gradient */
    text-decoration: none; /* Remove underline from links */
    font-weight: bold; /* Bold links for emphasis */
}

.whatsnew:hover {
    text-decoration: underline; /* Underline links on hover */
}