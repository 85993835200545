.create-account {
  text-align: center;
  color: white;
  /* background-color: hsl(218, 41%, 15%);
  background-image: radial-gradient(650px circle at 0% 0%,
      hsl(218, 41%, 35%) 15%,
      hsl(218, 41%, 30%) 35%,
      hsl(218, 41%, 20%) 75%,
      hsl(218, 41%, 19%) 80%,
      transparent 100%),
    radial-gradient(1250px circle at 100% 100%,
      hsl(218, 41%, 45%) 15%,
      hsl(218, 41%, 30%) 35%,
      hsl(218, 41%, 20%) 75%,
      hsl(218, 41%, 19%) 80%,
      transparent 100%); */
background-color: #0c1c45;
      padding: 20px;
  padding-top: 80px;
  overflow-y: hidden;
  overflow-x: hidden;
}

h2 {
  font-size: 24px;
  
  margin-top: 20px; 
  color: white;
}

.button-group-toggle {
  display: inline-flex;
  background-color: #fff;
  border-radius: 30px;
  padding: 5px;
  margin-top: 10px;
}

.button-group-toggle button {
  border: none;
  outline: none;
  padding: 10px 20px;
  border-radius: 30px;
  background-color: transparent;
  color: black;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease;
 
}

.button-group-toggle .active {
  background-color: #007bff; /* Blue for the active button */
  color: white;
}

.button-group-toggle button:not(.active):hover {
  background-color: rgba(255, 255, 255, 0.1); /* Hover effect for inactive buttons */
}

/* Paper component for wrapping the form content */
.paper-wrapper {

  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
  margin: 10px auto;

}