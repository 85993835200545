.plan-info-container {
  width: 100%;
  height: auto;
  padding: 20px;
  background-color: hsl(218, 41%, 15%);
  background-image: radial-gradient(650px circle at 0% 0%,
      hsl(218, 41%, 35%) 15%,
      hsl(218, 41%, 30%) 35%,
      hsl(218, 41%, 20%) 75%,
      hsl(218, 41%, 19%) 80%,
      transparent 100%),
    radial-gradient(1250px circle at 100% 100%,
      hsl(218, 41%, 45%) 15%,
      hsl(218, 41%, 30%) 35%,
      hsl(218, 41%, 20%) 75%,
      hsl(218, 41%, 19%) 80%,
      transparent 100%);
  display: flex;
  flex-direction: column;
}

.plan-info-container .back-button-container {
  display: flex;
  justify-content: center; /* Centers the button horizontally */
  margin-top: 20px; /* Adds some spacing above the button */
}

.plan-info-container .back-button {
  padding: 10px 20px;
  background-color: #00649f;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
}

.plan-info-container .back-button:hover {
  background-color: #005080;
}

.plan-info-container .plan-content-wide {
  display: flex;
  flex: 1;
  gap: 20px;
}

.plan-info-container .features-container {
  flex: 1;
  background-color: black;
  padding: 20px;
  border-radius: 8px;
  border: 1px solid #ddd;
}

.plan-info-container .features-container h2 {
  margin-bottom: 15px;
  color: #fff;
  text-align: center;
}

.plan-info-container .features-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

.plan-info-container .feature-item {
  padding: 10px;
  background: #f3f6f9;
  border-radius: 8px;
  border-left: 4px solid #00649f;
}

.plan-info-container .price-details {
  flex: 1;
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  border: 1px solid #ddd;
  text-align: center;


}

.plan-info-container .price-details h2{
  margin-bottom: 10px;
  margin-top: 10px;
  color: #111;
}

.plan-info-container .range-selector {
  margin-bottom: 20px;
  color: #fff;
  background-color: #fff;
}

.plan-info-container .year-adjustment {
  display: flex;
  align-items: center;
  justify-content: space-between;

  color: #111;
}

.plan-info-container .year-adjustment button {
  width: 40px;
  height: 40px;
  font-size: 18px;
  color: #fff;
  background-color: #00649f;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.plan-info-container .year-adjustment button:hover {
  background-color: #005080;

}

.plan-info-container .year-adjustment span {
  font-size: 16px;
  font-weight: bold;

}

.plan-info-container .payment-details h3 {
  margin-bottom: 15px;

  text-align: center;
}

.plan-info-container .payment-details ul {
  list-style: none;
  padding: 0;

}

.plan-info-container .payment-details li {
  display: flex;
  justify-content: space-between;
  margin: 8px 0;

}

/* PlanInfo.css */

/* Add-ons container */
.add-ons {
  margin-top: 20px;
}

/* Add-ons container */
.add-ons h3 {
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: bold;
}

/* Add-ons grid layout */
.add-ons-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 10px; /* Space between rows and columns */
}

/* Add-ons item */
.add-ons-item {
  flex: 0 0 calc(50% - 16px); /* Two items per row with spacing */
  display: flex;
  align-items: center;
}


/* Checkbox styling (optional for alignment) */
.add-ons-item .MuiCheckbox-root {
  margin-right: 8px;
}
