/* TablePagination Styles */
.textField-root .MuiInputBase-root {
  background-color: #fff;
  border: #000000;
  border-radius: 8px;
  font-size: 12px;
  font-family: 'Poppins', 'Helvetica', sans-serif;
}

.textField-root .MuiOutlinedInput-notchedOutline {
  border: 1px solid #bbb9b9;
  font-family: 'Poppins', 'Helvetica', sans-serif;
}

.textField-root .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: #000000;
  font-family: 'Poppins', 'Helvetica', sans-serif;
}

.textField-root .MuiInputLabel-asterisk {
  color: red; /* Set asterisk color to red */
}

.textField-root .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #3498DB;
  font-family: 'Poppins', 'Helvetica', sans-serif;
}

.textField-root .MuiInputLabel-outlined.Mui-focused {
  background-color: #3498DB;
  padding: 0 20px;
  border-radius: 10px;
  color: #fff;
  font-size: 14px;
  font-family: 'Poppins', 'Helvetica', sans-serif;
}

.textField-root .MuiInputLabel-outlined {
  font-size: 14px;
  font-family: 'Poppins', 'Helvetica', sans-serif;
}

.MuiButton-root,
.MuiTypography-root,
.MuiDialogTitle-root,
.MuiDialogContent-root,
.MuiMenuItem-root,
.MuiFormControl-root,
.MuiTableCell-root,
.MuiTableRow-root {
  font-family: 'Poppins', 'Helvetica', sans-serif;
}

.table-root .MuiTableCell-root {
  font-size: 12px;
  padding: 4px;
  font-family: 'Poppins', 'Helvetica', sans-serif;
}

.table-root .MuiTableHead-root .MuiTableCell-root {
  font-weight: bold;
  font-size: 14px;
  white-space: nowrap;
  text-align: center;
}

.table-root .MuiTableHead-root {
  text-align: center;
  background-color: #f2f2f2;
}

.table-root .MuiTableCell-root {
  white-space: nowrap;
}

.table-root .MuiTableCell-root:last-child {
  border-right: none;
}

.table-root .MuiTableRow-root {
  font-weight: bold;
  text-align: left; /* Align text to the left */
}

.table-root .MuiTableCell-root {
  text-align: left; /* Align table cell content to the left */
}

.table-root .MuiTableCell-root,
.table-root .MuiTableHead-root {
  text-align: center; /* Center the header */
}

/* Table Pagination Styling */
.MuiTablePagination-root {
  background-color: #ffffff; /* White background */
  padding: 10px 20px;
  border-radius: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  animation: fadeIn 0.6s ease-out; /* Smooth fade-in effect */
}

/* TablePagination Select (rows per page dropdown) */
.MuiTablePagination-select {
  background-color: #f0f0f0;
  border-radius: 8px;
  padding: 5px 15px;
  font-size: 16px;
  font-weight: 500;
  transition: all 0.3s ease-in-out;
}

.MuiTablePagination-select:hover {
  background-color: #e0e0e0;
}

/* Pagination Buttons */
.MuiTablePagination-actions button {
  padding: 6px 12px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 600;
  border: 1px solid #ddd;
  background-color: #007bff; /* Default button color */
  color: #fff;
  transition: background-color 0.3s ease-in-out;
}

.MuiTablePagination-actions button:hover {
  background-color: #0056b3; /* Darker blue on hover */
}


.MuiTablePagination-actions button.Mui-disabled {
  cursor: not-allowed; /* Disabled pointer cursor */
}

/* Fade-in animation for TablePagination */
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* SweetAlert2 Custom Styles */
.swal2-popup {
  width: 300px !important; /* Set width of the popup */
  height: auto !important; /* Let height adjust based on content */
  border-radius: 8px !important; /* Adjust border radius */
  font-family: 'Poppins', 'Helvetica', sans-serif !important; /* Use Poppins font */
  padding: 8px !important; /* Adjust padding */
}

.swal2-title {
  font-size: 15px !important; /* Set title font size */
  font-family: 'Poppins', 'Helvetica', sans-serif !important; /* Use Poppins font for the title */
}

.swal2-content {
  font-size: 13px !important; /* Set content font size */
  font-family: 'Poppins', 'Helvetica', sans-serif !important; /* Use Poppins font for the content */
  padding: 8px 0 !important; /* Adjust content padding */
}

.swal2-actions {
  margin-top: 10px !important; /* Add margin between content and buttons */
}

.swal2-confirm, .swal2-cancel {
  font-size: 14px !important; /* Set button font size */
  padding: 8px 20px !important; /* Adjust button padding */
  border-radius: 6px !important; /* Adjust button border radius */
}

.swal2-icon {
  width: 80px !important; /* Set icon width */
  height: 80px !important; /* Set icon height */
}

.swal2-icon.swal2-warning {
  color: #f1c40f !important; /* Adjust color for warning icon */
}




.income {
  color: #28a745; /* Green */
}

.expense {
  color: #dc3545; /* Red */
}

.savings-positive {
  color: #28a745; /* Green */
}

.savings-negative {
  color: #dc3545; /* Red */
}


/* Add this CSS to your stylesheet or inside a styled component */
.required-asterisk .MuiFormLabel-asterisk {
  color: red;
}

@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@keyframes blink {
  from,
  to {
    border-right-color: transparent;
  }
 
}

.typing-effect {
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  border-right: 2px solid black; /* Cursor effect */
  animation: typing 4s steps(30, end), blink 0.5s step-end infinite;
}



